import { Stack } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FarmsInfo } from '../../state/farms/reducer'

const ContainerWithBorder = styled.div`
  position: relative;
  width: 100%;
  padding: 1px; /* Adjust if needed */
  border-radius: 32px;
  background: conic-gradient(from 180deg at 50% 50%, #ffffff 0deg, #2e2e2e 41.2deg, #2e2e2e 317.04deg, #ffffff 360deg);
`

const FarmContainer = styled.div`
  background: #181818;
  border-radius: 32px;
  box-shadow: 0px 0px 63.5px 2px #49494940;
  padding: 20px;
`
const FarmTitle = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.64px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #ffffff;
  margin: 0px;
`
const StyledImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`
const InfoText = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0px;
`
const GoToButton = styled.div`
  background: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 14.32px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;
  border: none;
  cursor: pointer;
  border-radius: 44px;
  padding: 10px 20px;
`
export default function FarmCard(props: FarmsInfo) {
  const { pid, name, tvl, assets, apr, stakedAmount } = props
  const history = useHistory()
  return (
    <ContainerWithBorder>
      <FarmContainer>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2}>
            <Stack direction="row">
              <StyledImg src={`/images/tokens/${assets.token0.symbol.toLowerCase()}.png`} alt={assets.token0.symbol} />
              <StyledImg src={`/images/tokens/${assets.token1.symbol.toLowerCase()}.png`} alt={assets.token1.symbol} />
            </Stack>
            <FarmTitle>{name}</FarmTitle>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <InfoText>APR:</InfoText>
              <InfoText style={{ color: '#9F9F9F' }}>{parseFloat(apr).toFixed(4)} %</InfoText>
            </Stack>
            <Stack direction="row" spacing={1}>
              <InfoText>Pool Value:</InfoText>
              <InfoText style={{ color: '#9F9F9F' }}>$ {parseFloat(tvl).toFixed(5)}</InfoText>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="center">
            <InfoText style={{ fontSize: '15px' }}>Deposited Amount:</InfoText>
            <InfoText style={{ color: '#9F9F9F', fontSize: '15px' }}>
              {parseFloat(stakedAmount.toString()).toFixed(3)} {assets.token0.symbol}-{assets.token1.symbol}
            </InfoText>
          </Stack>
          <GoToButton onClick={() => history.push(`/farm/${pid}`)}>Invest in {name}</GoToButton>
        </Stack>
      </FarmContainer>
    </ContainerWithBorder>
  )
}
