import React from 'react'
import { styled as muiStyled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { ArrowUpCircle } from 'react-feather'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import { Stack } from '@mui/material'
import BigNumber from 'bignumber.js'

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})

const BootstrapDialog = muiStyled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    background: '#181818'
  },
  '& .MuiDialogActions-root': {
    background: '#181818'
  },
  '& .MuiDialog-paper': {
    background: '#181818',
    border: '1px solid #2E2E2E',
    boxShadow: '0px 0px 63.5px 2px #49494940',
    borderRadius: '32px',
    padding: '20px',
    minWidth: '400px'
  }
}))
const ModalTitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #b7b7b7;
  text-align: center;
  margin: 0px;
`
export interface SimpleDialogProps {
  open: boolean
  onClose?: () => void
  title: string
}
export default function SuccessModal(props: SimpleDialogProps) {
  const { onClose, open, title } = props
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ModalTitle>{title}</ModalTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack spacing={4} sx={{ marginTop: '30px' }} justifyContent="center" alignItems="center">
        <ArrowUpCircle strokeWidth={0.5} size={90} color="#fff" />

        <SubTitleText style={{ textAlign: 'center', fontSize: '16px' }}>Transcation Successfull</SubTitleText>
        <StyledButton style={{ width: '100%' }} onClick={handleClose}>
          Close
        </StyledButton>
      </Stack>
    </BootstrapDialog>
  )
}
