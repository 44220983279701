import React, { useState } from 'react'
import Container from '@mui/material/Container'
import { styled as muiStyled } from '@mui/material/styles'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useParams } from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { LineChart } from '@mui/x-charts/LineChart'
import TableRow from '@mui/material/TableRow'
import FarmRow from '../../components/FarmRow'
import { useGetFarmFromPid, getFarmFromPid } from '../../state/farms/hooks'
import DepositFarmModal from '../../components/DepositFarmModal'
import { useMulticallContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import { updateFarm } from '../../state/farms/actions'
import { AppDispatch } from '../../state/index'

const BootstrapTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2E2E2E'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2E2E2E',
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '13px',
    lineHeight: '19px',
    borderRadius: '10px'
  }
}))

const StyledTableCell = muiStyled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2E2E2E',
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#BEBEBE',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500
  }
}))

const StyledTableHeaderRow = muiStyled(TableRow)(() => ({
  '& th:first-child': {
    borderRadius: '12px 0 0 12px'
  },
  '& th:last-child': {
    borderRadius: '0 12px 12px 0'
  }
}))
const TitleText = styled.h2`
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.32px;
  text-align: center;
  color: #fff;
`
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #bebebe;
  text-align: left;
  margin: 0px;
`
const InfoContainer = styled.div`
  background: #181818;
  box-shadow: 0px 0px 63.5px 2px #49494940;
  padding: 20px;
  border-radius: 32px;
  height: 100%;
`
export const ContainerWithBorder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1px; /* Adjust if needed */
  border-radius: 32px;
  background: conic-gradient(from 180deg at 50% 50%, #ffffff 0deg, #2e2e2e 41.2deg, #2e2e2e 317.04deg, #ffffff 360deg);
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
interface UserPositions {
  relicId: any
  amount: string
  duration: any
  rewardCredit: any
  rewardDebt: any
  level: any
  isApproved: boolean
}
interface ReliqueryData {
  name: string
  tvl: string
  stakedAmount: string
  rewardsAmount: string
  poolToken: string
  lpPrice: string
  rewardToken: {
    name: string
    decimals: string
    symbol: string
    address: string
  }
}
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
  isSingleSided: boolean
}
export default function FarmDetail() {
  const { pid }: { pid: string } = useParams()
  const farmFromPid = useGetFarmFromPid(pid)
  console.log('farmFromPid', farmFromPid)
  const dispatch = useDispatch<AppDispatch>()
  const [showDepositModal, setShowDepositModal] = useState(false)
  const multicallContract = useMulticallContract()
  const { account } = useActiveWeb3React()
  const updateUserPositions = async () => {
    try {
      if (farmFromPid) {
        const farm = await getFarmFromPid(
          farmFromPid?.pid.toString(),
          farmFromPid?.rewardToken.address,
          farmFromPid?.rewardRate,
          multicallContract,
          account
        )
        dispatch(updateFarm({ pid: farmFromPid.pid.toString(), farm: farm }))
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleClose = () => {
    setShowDepositModal(false)
    updateUserPositions()
  }
  return (
    <Container maxWidth="lg">
      {farmFromPid && showDepositModal && (
        <DepositFarmModal open={showDepositModal} inputTokens={farmFromPid?.inputTokens} onClose={handleClose} />
      )}
      <TitleText>Liquidity Pool</TitleText>
      {farmFromPid && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ContainerWithBorder>
                <InfoContainer>
                  <TitleText style={{ fontSize: '24px', margin: '0px' }}>Total slCHIMP</TitleText>
                  <Stack spacing={3} sx={{ marginTop: '30px' }}>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <SubTitleText>Liquidity Pool APR %</SubTitleText>
                      <SubTitleText style={{ color: '#fff' }}>{parseFloat(farmFromPid.apr).toFixed(4)} %</SubTitleText>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <SubTitleText>slCHIMP Price</SubTitleText>
                      <SubTitleText style={{ color: '#fff' }}>
                        $ {parseFloat(farmFromPid.lpPrice).toFixed(2)}
                      </SubTitleText>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <SubTitleText>TVL</SubTitleText>
                      <SubTitleText style={{ color: '#fff' }}>$ {parseFloat(farmFromPid.tvl).toFixed(2)}</SubTitleText>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <SubTitleText>Staked slCHIMP</SubTitleText>
                      <SubTitleText style={{ color: '#fff' }}>
                        {farmFromPid.stakedAmount} {farmFromPid.name}
                      </SubTitleText>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <SubTitleText>Your Rewards</SubTitleText>
                      <SubTitleText style={{ color: '#fff' }}>
                        {farmFromPid.rewardsAmount} {farmFromPid.rewardToken.symbol}
                      </SubTitleText>
                    </Stack>
                    {/* <StyledButton onClick={collectRewards}>Collect Rewards</StyledButton> */}
                  </Stack>
                </InfoContainer>
              </ContainerWithBorder>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ContainerWithBorder>
                <InfoContainer>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <TitleText style={{ fontSize: '24px', margin: '0px' }}>Staking Power</TitleText>
                    <BootstrapTooltip title="Staking power is multiplied by your deposit which increases your farming and governance rewards. See our docs to learn more.">
                      <IconButton>
                        <InfoIcon sx={{ fontSize: '14px' }} />
                      </IconButton>
                    </BootstrapTooltip>
                  </Stack>
                  <Stack justifyContent="center" alignItems="center">
                    <LineChart
                      sx={{
                        // bottomAxis Line Styles
                        '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
                          stroke: '#C6C6C5',
                          strokeWidth: 0.4
                        },
                        // leftAxis Line Styles
                        '& .MuiChartsAxis-left .MuiChartsAxis-line': {
                          stroke: '#C6C6C5',
                          strokeWidth: 0.4
                        }
                      }}
                      colors={['#8B8DFC']}
                      xAxis={[{ data: [0, 1, 7, 14, 30, 90, 180, 365], label: 'Days Staked', fill: '#FFFFFFBF' }]}
                      series={[
                        {
                          data: [100, 120, 140, 150, 175, 200, 275, 350],
                          label: 'Power'
                        }
                      ]}
                      yAxis={[{ label: 'Power' }]}
                      bottomAxis={{
                        disableTicks: true,
                        stroke: '#fff',
                        labelStyle: {
                          fill: '#fff'
                        },
                        tickLabelStyle: {
                          fill: '#FFFFFFBF'
                        }
                      }}
                      leftAxis={{
                        disableTicks: true,
                        stroke: '#fff',
                        labelStyle: {
                          fill: '#fff'
                        },
                        tickLabelStyle: {
                          fill: '#FFFFFFBF'
                        }
                      }}
                      width={isMobile ? 280 : 500}
                      height={300}
                    />
                  </Stack>
                </InfoContainer>
              </ContainerWithBorder>
            </Grid>
          </Grid>
          <ContainerWithBorder style={{ marginTop: '30px' }}>
            <InfoContainer>
              <Stack sx={{ marginBottom: '20px' }} justifyContent="space-between" alignItems="center" direction="row">
                <TitleText style={{ fontSize: '24px', margin: '0px', textAlign: 'left' }}>My NFTs</TitleText>
                {farmFromPid.userPositions.length > 0 && (
                  <StyledButton onClick={() => setShowDepositModal(true)} style={{ padding: '9px 20px' }}>
                    Create new Position
                  </StyledButton>
                )}
              </Stack>
              {/* <TableContainer component={Paper}> */}
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledTableHeaderRow>
                    <StyledTableCell align="center">NFT</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                    <StyledTableCell align="center">Duration</StyledTableCell>
                    <StyledTableCell align="center">Level</StyledTableCell>
                    <StyledTableCell align="center">Rewards</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableHeaderRow>
                </TableHead>
                {farmFromPid.userPositions.length > 0 && (
                  <TableBody>
                    {farmFromPid.userPositions.map((nft: any, index: number) => (
                      <FarmRow
                        key={nft.relicId}
                        {...nft}
                        inputTokens={farmFromPid.inputTokens}
                        index={index}
                        rewardToken={farmFromPid.rewardToken}
                        onUpdatePosition={updateUserPositions}
                        nftToBeMergedWith={farmFromPid.userPositions[0]}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
              {farmFromPid.userPositions.length === 0 && (
                <Stack spacing={3} justifyContent="center" alignItems="center" sx={{ marginTop: '30px' }}>
                  <TitleText style={{ fontSize: '24px', margin: '0px', textAlign: 'left' }}>
                    You don’t have any NFT’s Positions open
                  </TitleText>
                  <StyledButton onClick={() => setShowDepositModal(true)} style={{ padding: '9px 20px' }}>
                    Create Position
                  </StyledButton>
                </Stack>
              )}
              {/* </TableContainer> */}
            </InfoContainer>
          </ContainerWithBorder>
        </>
      )}
    </Container>
  )
}
