import { ChainId } from '@auroblocks/uniswap-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.MATICTESTNET]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.TEN_TESTNET]: '0x66fd71b7Afd98222f34f25Fe66845216eFEAeB19',
  [ChainId.ARBITRUMMAINNET]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
