// import { INITIAL_ALLOWED_SLIPPAGE, DEFAULT_DEADLINE_FROM_NOW } from '../../constants'
import { createReducer } from '@reduxjs/toolkit'
import { setFarms, updateFarm } from './actions'

export interface UserPositions {
  relicId: any
  amount: string
  duration: any
  rewardCredit: any
  rewardDebt: any
  level: any
  isApproved: boolean
  pendingReward: string
}
export interface TokenDetails {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
  isSingleSided: boolean
}
export interface FarmsInfo {
  pid: string | number
  lpAddress: any
  name: any
  accRewardPerShare: any
  tvl: string
  lpPrice: string
  apr: string
  lastRewardTime: string
  stakedAmount: string
  rewardsAmount: string
  userPositions: UserPositions[] | any
  rewardToken: TokenDetails
  rewardRate: string
  inputTokens: TokenDetails[]
  assets: {
    token0: TokenDetails
    token1: TokenDetails
  }
}
export interface FarmState {
  farms: FarmsInfo[]
}

export const initialState: FarmState = {
  farms: []
}

export default createReducer(initialState, builder =>
  builder
    .addCase(setFarms, (state, action) => {
      state.farms = action.payload.farms
    })
    .addCase(updateFarm, (state, action) => {
      const updatedFarms = state.farms.map(farm => {
        if (farm.pid.toString() === action.payload.pid.toString()) {
          return action.payload.farm
        } else {
          return farm
        }
      })
      state.farms = [...updatedFarms]
    })
)
