import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { STEPS } from '../../constants/index'
import { AppState } from '../../state'
import FarmCard from '../../components/FarmCard'
import { FarmsInfo } from '../../state/farms/reducer'

const TitleText = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.32px;
  text-align: center;
  color: #fff;
`
const SectionTitleText = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 40.32px;
  text-align: left;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 20px;
`
const HowItWorksContainer = styled.div`
  background: #181818;
  border: 1px solid #2e2e2e;
  box-shadow: 0px 0px 25.4px -13px #00000040;
  border-radius: 25px;
  padding: 15px;
`
const HowItWorksCard = styled.div`
  background: #252525;
  border-radius: 23px;
  padding: 10px 20px;
  margin-bottom: 20px;
`
const HowITWorkTitle = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 40.32px;
  text-align: left;
  color: #fff;
  margin: 0px;
`
const HowITWorkDescription = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #fff;
  margin: 0px;
`
export default function Farms() {
  const farmsRedux = useSelector<AppState, AppState['farms']['farms']>(state => state.farms.farms)
  return (
    <Container maxWidth="lg">
      <TitleText>Chimp Time-based Farm</TitleText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Grid container spacing={2}>
            {farmsRedux &&
              farmsRedux.map((farm: FarmsInfo) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={`${farm.pid}-${farm.lpAddress}`}>
                  <FarmCard {...farm} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <HowItWorksContainer>
            <SectionTitleText>How it Works</SectionTitleText>
            {STEPS.map(step => (
              <HowItWorksCard key={step.title}>
                <HowITWorkTitle>{step.title}</HowITWorkTitle>
                <HowITWorkDescription style={{ marginTop: '10px' }}>{step.description}</HowITWorkDescription>
              </HowItWorksCard>
            ))}
          </HowItWorksContainer>
        </Grid>
      </Grid>
    </Container>
  )
}
