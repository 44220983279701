import * as React from 'react'
import styled from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled as muiStyled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AppBar from '@mui/material/AppBar'
import { useHistory, useLocation } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { Stack } from '@mui/material'
import { ROUTES } from '../../constants/index'

const CustomIconButton = muiStyled(IconButton)({
  height: '44px',
  width: '44px',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '12px'
})
const StyledOptionText = styled.p`
  margin: 0px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`
export default function BottomTabs() {
  const history = useHistory()
  const location = useLocation()
  const navigateToPath = (path: string, isLive: boolean) => {
    if (isLive) {
      history.push(path)
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openAr = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSwapToggleChange = (type: string) => {
    console.log('event', type)
    if (type === 'tradeOnTen') {
      history.push('/swap')
    } else if (type === 'tradeOnLinea') {
      window.open('https://app.chimp.exchange/#/linea/swap', '_blank')
    }
    handleClose()
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        minHeight: '82px',
        top: 'auto',
        bottom: 0,
        background: 'linear-gradient(149.65deg,#0f0f0f -2.81%,#0a0b49 75.27%)',
        borderRadius: '22px 22px 0px 0px'
      }}
    >
      <Toolbar sx={{ width: '100%', minHeight: '82px' }}>
        <Stack spacing={2} alignItems="center" direction="row" sx={{ width: '100%' }} justifyContent="space-evenly">
          {ROUTES.map(route => (
            <>
              {route.isDropDown ? (
                <>
                  <CustomIconButton
                    onClick={handleClick}
                    sx={{
                      background: route.path.includes(location.pathname) ? '#FFFFFF1F' : 'transparent'
                    }}
                    key={route.path}
                    color="inherit"
                  >
                    <img src={route.path.includes(location.pathname) ? route.iconSelected : route.icon} width="15px" />
                    <KeyboardArrowDownIcon sx={{ fontSize: '14px', marginLeft: '5px' }} />
                  </CustomIconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openAr}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {route.options.map(option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        onClick={() => handleSwapToggleChange(option.id)}
                        sx={{ display: option.id === 'swap' ? 'none' : 'auto', padding: '16px 20px 16px 20px' }}
                      >
                        <Stack alignItems="center" direction="row">
                          <img
                            src={route.path.includes(location.pathname) ? option.iconSelected : option.icon}
                            width={'24px'}
                            style={{
                              marginRight: '10px'
                            }}
                          />
                          <StyledOptionText>{option.title}</StyledOptionText>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <CustomIconButton
                  onClick={() => navigateToPath(route.path, route.isLive)}
                  sx={{
                    background: route.path.includes(location.pathname) ? '#FFFFFF1F' : 'transparent'
                  }}
                  key={route.path}
                  color="inherit"
                >
                  <img src={route.path.includes(location.pathname) ? route.iconSelected : route.icon} width="20px" />
                </CustomIconButton>
              )}
            </>
          ))}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
