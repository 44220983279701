import React, { useState, useEffect } from 'react'
import { styled as muiStyled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useReliqueryContract } from '../../hooks/useContract'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import { ArrowUpCircle } from 'react-feather'
import { Stack } from '@mui/material'
import BigNumber from 'bignumber.js'

const BootstrapDialog = muiStyled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    background: '#181818'
  },
  '& .MuiDialogActions-root': {
    background: '#181818'
  },
  '& .MuiDialog-paper': {
    background: '#181818',
    border: '1px solid #2E2E2E',
    boxShadow: '0px 0px 63.5px 2px #49494940',
    borderRadius: '32px',
    padding: '20px',
    minWidth: '400px'
  }
}))
const CssTextField = muiStyled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    background: '#333333',
    borderRadius: '57px',
    color: '#EAEAEA',
    '& .MuiInputBase-input': {
      color: '#EAEAEA',
      fontFamily: 'Inter',
      fontSize: '14px',
      height: '29px',
      fontWeight: 400
    },
    '& fieldset': {
      borderColor: '#2E2E2E'
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C'
    }
  }
})

const ModalTitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const InfoLabel = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #b7b7b7;
  text-align: center;
  margin: 0px;
`
const MaxButton = styled.button`
  background-color: #181818;
  border: 1px solid #181818;
  font-size: 14px;
  border-radius: 32px;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
`
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
}
export interface SimpleDialogProps {
  open: boolean
  maxAmount: string
  selectedValue?: string
  onClose?: () => void
  inputTokens: InputTokens[]
  relicId?: string
}
export default function WithdrawFarmModal(props: SimpleDialogProps) {
  const { onClose, open, inputTokens, relicId, maxAmount } = props
  const reliqueryContract = useReliqueryContract()
  const [selectedToken, setSelectedToken] = useState<InputTokens | null>(null)
  const [txProcessing, setTxProcessing] = useState(false)
  const [amount, setAmount] = useState('0')
  const [txSuccess, setTxSuccess] = useState(false)
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleWithdraw = async () => {
    try {
      if (reliqueryContract && selectedToken) {
        setTxProcessing(true)
        const amountInWei = new BigNumber(amount).multipliedBy(10 ** parseFloat(selectedToken.decimals)).toString()
        const tx = await reliqueryContract.withdraw(amountInWei, relicId)
        await tx.wait()
        setTxProcessing(false)
        setTxSuccess(true)
      }
    } catch (error) {
      console.log('error', error)
      setTxProcessing(false)
    }
  }
  useEffect(() => {
    if (selectedToken === null && inputTokens.length > 0) {
      setSelectedToken(inputTokens[0])
    }
  }, [inputTokens, selectedToken])
  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ModalTitle>Withdraw Tokens</ModalTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {txSuccess ? (
        <Stack spacing={4} sx={{ marginTop: '30px' }} justifyContent="center" alignItems="center">
          <ArrowUpCircle strokeWidth={0.5} size={90} color="#fff" />

          <SubTitleText style={{ textAlign: 'center', fontSize: '16px' }}>Transcation Successfull</SubTitleText>
          <StyledButton style={{ width: '100%' }} onClick={handleClose}>
            Close
          </StyledButton>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ marginTop: '30px' }}>
          <div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '0px 10px 8px 8px' }}
            >
              <InfoLabel>Available to Withdraw: </InfoLabel>
              {selectedToken && <InfoLabel>{parseFloat(maxAmount).toFixed(4)}</InfoLabel>}
            </Stack>
            <CssTextField
              size="small"
              id="custom-css-outlined-input"
              fullWidth
              type="number"
              value={amount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MaxButton onClick={() => setAmount(maxAmount)}>Max</MaxButton>
                  </InputAdornment>
                )
              }}
              onChange={event => setAmount(event.target.value)}
            />
          </div>
          <StyledButton disabled={txProcessing} onClick={handleWithdraw}>
            {txProcessing ? 'Processing...' : 'Withdraw'}
          </StyledButton>
        </Stack>
      )}
    </BootstrapDialog>
  )
}
