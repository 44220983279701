// @ts-nocheck

import * as React from 'react'
import styled from 'styled-components'
import { styled as muiStyled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useHistory } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import XIcon from '@mui/icons-material/X'
import discord from '../../assets/images/discord.png'
import { useActiveWeb3React } from '../../hooks'
// import { useETHBalances } from '../../state/wallet/hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation } from 'react-router-dom'
import bgImg from '../../assets/images/bgImg.png'
import { ROUTES } from '../../constants/index'
import Logo from '../../assets/images/chimp-black-logo.png'
import LogoDark from '../../assets/images/chimp-black-logo.png'
import chimp from '../../assets/images/chimp.png'
// import discordIcon from '../../assets/images/discord.png'
import { Stack } from '@mui/material'
import Web3Status from '../Web3Status'
import { useSetFarms } from '../../state/farms/hooks'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AccountBalance = styled.div`
  position: relative;
  background: linear-gradient(0deg, #ffffff 0%, #f1f1f1 100%);
  box-shadow: 0px 0px 15.8px 6px #ffffff40;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
`
const CustomIconButton = muiStyled(IconButton)`
  background: #4a487b;
  border: 0.5px solid;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 56px;
  position: fixed; 
  transition: all 0.3s ease-out;
`

const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))
const StyledOptionText = styled.p`
  margin: 0px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`
const RouteText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
`
// const PointsContainer = styled.div`
//   background: #ffffff1a;
//   width: 100%;
//   border: 0.5px solid;
//   padding: 24px 16px 16px 16px;
//   border-radius: 28px;
//   border-image-source: radial-gradient(100% 100% at 0% 0%, rgba(14, 11, 58, 0.48) 0%, rgba(14, 11, 58, 0.48) 100%)
//     /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
// `
// const PointsTitleText = styled.p`
//   font-family: Inter;
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 24.96px;
//   letter-spacing: 0.01em;
//   text-align: center;
//   color: #ffffff;
//   margin-top: 0px;
// `
// const PointsSubTitleText = styled.p`
//   font-family: Inter;
//   font-family: Inter;
//   font-size: 13px;
//   font-weight: 500;
//   line-height: 20.8px;
//   text-align: center;
//   margin-top: 0px;
//   color: #ffffff8f;
//   white-space: pre-line;
// `
export default function MiniDrawer(props: any) {
  const [open, setOpen] = React.useState(true)
  const { chainId } = useActiveWeb3React()
  const history = useHistory()
  const location = useLocation()
  useSetFarms()
  const [isDark] = useDarkModeManager()
  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const handleToggle = () => {
    setOpen(!open)
  }
  const navigateToPath = (path: string, isLive: boolean) => {
    if (isLive) {
      history.push(path)
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openAr = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSwapToggleChange = type => {
    console.log('event', type)
    if (type === 'tradeOnTen') {
      history.push('/swap')
    } else if (type === 'tradeOnLinea') {
      window.open('https://app.chimp.exchange/#/linea/swap', '_blank')
    }
    handleClose()
  }
  React.useEffect(() => {
    const switchNetwork = async () => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          // params: [{ chainId: '0x1' }],
          params: [{ chainId: `0x${parseFloat('443').toString(16)}` }]
        })
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        console.log('switchError', switchError)
        // handle other "switch" errors
      }
    }
    if (window.ethereum && chainId !== undefined && chainId.toString() !== '443') {
      switchNetwork()
    }
  }, [chainId])
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%'
      }}
    >
      {/* <CssBaseline /> */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{ '& .MuiPaper-root': { background: 'transparent', border: 'none', transition: 'left 1s linear' } }}
      >
        <DrawerHeader>
          {open ? (
            <img width={'210px'} src={isDark ? LogoDark : Logo} alt="logo" />
          ) : (
            <img width={'30px'} src={chimp} alt="logo" style={{ marginRight: '1px' }} />
          )}

          <CustomIconButton onClick={handleToggle} sx={{ left: open ? '240px' : '62px' }}>
            {open ? <ChevronLeftIcon sx={{ color: '#fff' }} /> : <ChevronRightIcon sx={{ color: '#fff' }} />}
          </CustomIconButton>
        </DrawerHeader>
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginTop: '20px'
          }}
        >
          <List sx={{ width: '100%' }}>
            {ROUTES.map(route => (
              <ListItem key={route.title} sx={{ display: 'block' }}>
                {route.isDropDown ? (
                  <>
                    <ListItemButton
                      onClick={handleClick}
                      sx={{
                        minHeight: 48,
                        minWidth: '44px',
                        justifyContent: open ? 'initial' : 'center',
                        padding: open ? '16px 20px 16px 20px' : '0px 12px',
                        background: route.path.includes(location.pathname) ? '#292A3C' : 'transparent',
                        borderRadius: '12px'
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? '15px' : 'auto',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={route.path.includes(location.pathname) ? route.iconSelected : route.icon}
                          width={open ? '20px' : '15px'}
                          style={{ marginRight: open ? '0px' : '5px' }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0
                        }}
                      >
                        <RouteText
                          style={{
                            color: route.path.includes(location.pathname) ? '#fff' : '#FFFFFF8F'
                          }}
                        >
                          {route.title}
                        </RouteText>
                      </ListItemText>
                      <KeyboardArrowDownIcon sx={{ fontSize: open ? '28px' : '14px' }} />
                    </ListItemButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openAr}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      {route.options.map(option => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          onClick={() => handleSwapToggleChange(option.id)}
                          sx={{ display: option.id === 'swap' ? 'none' : 'auto', padding: '16px 20px 16px 20px' }}
                        >
                          <Stack alignItems="center" direction="row">
                            <img
                              src={route.path.includes(location.pathname) ? option.iconSelected : option.icon}
                              width="24px"
                              style={{
                                marginRight: '15px'
                              }}
                            />
                            <StyledOptionText>{option.title}</StyledOptionText>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  <ListItemButton
                    onClick={() => navigateToPath(route.path, route.isLive)}
                    sx={{
                      minHeight: 48,
                      minWidth: '44px',
                      justifyContent: open ? 'initial' : 'center',
                      padding: open ? '16px 20px 16px 20px' : '0px 12px',
                      background: route.path.includes(location.pathname) ? '#292A3C' : 'transparent',
                      borderRadius: '12px'
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? '15px' : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <img
                        src={route.path.includes(location.pathname) ? route.iconSelected : route.icon}
                        width="20px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0
                      }}
                    >
                      <RouteText
                        style={{
                          color: route.path.includes(location.pathname) ? '#fff' : '#FFFFFF8F'
                        }}
                      >
                        {route.title}
                      </RouteText>
                    </ListItemText>
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
        </div>
        <Stack
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ padding: '0px 16px', width: '100%', position: 'absolute', bottom: '45px' }}
        >
          {open && <span style={{ fontWeight: '500' }}>Join Chimp Community</span>}
          <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            direction={open ? 'row' : 'column'}
            sx={{ width: '100%' }}
          >
            <Stack
              onClick={() => window.open('https://twitter.com/ChimpExchange', '_blank')}
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <IconButton onClick={() => window.open('https://twitter.com/ChimpExchange', '_blank')}>
                <XIcon sx={{ color: '#B6B6B6', fontSize: '24px' }} />
              </IconButton>
              <span style={{ fontSize: '12px' }}>X</span>
            </Stack>
            <Stack
              onClick={() => window.open('https://twitter.com/ChimpExchange', '_blank')}
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <IconButton onClick={() => window.open('https://discord.com/invite/eA7HPTtyjJ', '_blank')}>
                <img src={discord} alt="discord" width="24px" />
              </IconButton>
              <span style={{ fontSize: '12px' }}>Discord</span>
            </Stack>
            <Stack
              onClick={() => window.open('https://twitter.com/ChimpExchange', '_blank')}
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <IconButton onClick={() => window.open('https://docs.chimp.exchange/chimp-on-ten', '_blank')}>
                <TextSnippetIcon sx={{ color: '#B6B6B6', fontSize: '24px' }} />
              </IconButton>
              <span style={{ fontSize: '12px' }}>Docs</span>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
          background: '#000000',
          borderRadius: '37px',
          minHeight: '98vh',
          backgroundImage: `url(${bgImg})`,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Stack
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ padding: '0px 16px', position: 'fixed', top: '25px', right: '10px', zIndex: '1' }}
        >
          {/* <PointsContainer>
            <PointsTitleText>Earn Points</PointsTitleText>
            <PointsSubTitleText>Swap & earn points for next ChimpDrop</PointsSubTitleText>
            <AccountBalance style={{ width: open ? '100%' : '44px' }}>
              <AutoAwesomeSharpIcon sx={{ color: '#fff', marginRight: open ? '10px' : '0px', fontSize: '17px' }} />
              {open && `${userEthBalance?.toSignificant(4)} ETH`}
            </AccountBalance>
          </PointsContainer> */}
          <AccountBalance
            style={{ width: '100%', maxWidth: '180px', cursor: 'pointer' }}
            onClick={() => window.open('http://chimpdrop.chimp.exchange/', '_blank')}
          >
            <AutoAwesomeSharpIcon
              sx={{
                color: '#1f1f1f',
                marginRight: '10px',
                fontSize: '17px',
                position: 'absolute',
                left: '18px'
              }}
            />
            <p style={{ textAlign: 'center', color: '#1f1f1f', fontWeight: '500' }}>
              Points
              <br />
              <span style={{ fontSize: '12px', fontWeight: '400' }}>are Now Live</span>
            </p>
          </AccountBalance>
          <Web3Status open />
        </Stack>
        {props.children}
      </Box>
    </Box>
  )
}
