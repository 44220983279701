import { X } from 'react-feather'
import styled from 'styled-components'

export const StyledClose = styled(X)`
  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  return null
  // return isMobile ? (
  //   <PhishAlert isActive={showURLWarning}>
  //     <div style={{ display: 'flex' }}>
  //       <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure the URL is
  //       <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>app.uniswap.org</code>
  //     </div>
  //     <StyledClose size={12} onClick={toggleURLWarning} />
  //   </PhishAlert>
  // ) : window.location.hostname === 'app.uniswap.org' ? (
  //   <PhishAlert isActive={showURLWarning}>
  //     <div style={{ display: 'flex' }}>
  //       <AlertTriangle style={{ marginRight: 6 }} size={12} /> Always make sure the URL is
  //       <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>app.uniswap.org</code> - bookmark it
  //       to be safe.
  //     </div>
  //     <StyledClose size={12} onClick={toggleURLWarning} />
  //   </PhishAlert>
  // ) : null
}
