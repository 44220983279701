/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import BigNumber from 'bignumber.js'

import { ERC20_ABI } from '../../constants/abis/erc20'
import lpAbi from '../../constants/abis/lp-pair.json'
import { useActiveWeb3React } from '../../hooks'
import reliqueryAbi from '../../constants/abis/reliquery.json'
import { useReliqueryContract, useMulticallContract, useOwanalbleCurveContract } from '../../hooks/useContract'
import { setFarms } from './actions'
import { FarmsInfo, UserPositions } from './reducer'
import { AppDispatch, AppState } from '../index'
import { RELIQUERY_ADDRESS, SINGLE_SIDED_LIQUIDITY } from '../../constants'
import multicall from '../../utils/multicall'
import { Contract } from 'ethers'

export const getFarmFromPid = async (
  pid: string,
  rewardToken: string,
  rewardRate: string,
  multicallContract: Contract | null,
  account?: string | null | undefined
) => {
  const poolTokenCalls = []
  const userPositions: UserPositions[] = []
  poolTokenCalls.push({
    address: RELIQUERY_ADDRESS,
    name: 'poolToken',
    params: [pid]
  })
  poolTokenCalls.push({
    address: RELIQUERY_ADDRESS,
    name: 'getPoolInfo',
    params: [pid]
  })
  const poolTokenResp = await multicall(reliqueryAbi, poolTokenCalls, multicallContract)
  const poolToken = poolTokenResp[0][0]
  const poolInfo = poolTokenResp[1]
  const tokenCalls = [
    {
      address: poolToken,
      name: 'token0'
    },
    {
      address: poolToken,
      name: 'token1'
    },
    {
      address: poolToken,
      name: 'getReserves'
    },
    {
      address: poolToken,
      name: 'totalSupply'
    }
  ]
  const lpTokensResp = await multicall(lpAbi, tokenCalls, multicallContract)
  const tvlCallResp = await multicall(
    ERC20_ABI,
    [
      {
        address: poolToken,
        name: 'balanceOf',
        params: [RELIQUERY_ADDRESS]
      },
      {
        address: lpTokensResp[0][0],
        name: 'name'
      },
      {
        address: lpTokensResp[0][0],
        name: 'symbol'
      },
      {
        address: lpTokensResp[0][0],
        name: 'decimals'
      },
      {
        address: lpTokensResp[1][0],
        name: 'name'
      },
      {
        address: lpTokensResp[1][0],
        name: 'symbol'
      },
      {
        address: lpTokensResp[1][0],
        name: 'decimals'
      },
      {
        address: rewardToken,
        name: 'name'
      },
      {
        address: rewardToken,
        name: 'symbol'
      },
      {
        address: rewardToken,
        name: 'decimals'
      },
      {
        address: poolToken,
        name: 'name'
      },
      {
        address: poolToken,
        name: 'symbol'
      },
      {
        address: poolToken,
        name: 'decimals'
      }
    ],
    multicallContract
  )
  let lpPrice = '1'
  let apr = '0'
  const rewardTokenDetails = {
    name: tvlCallResp[7][0],
    symbol: tvlCallResp[8][0],
    decimals: tvlCallResp[9][0],
    address: rewardToken,
    allowence: '0',
    balance: '0',
    isSingleSided: false
  }
  const rewardsPerYear = new BigNumber(rewardRate.toString())
    .dividedBy(10 ** rewardTokenDetails.decimals)
    .multipliedBy(31536000)
  let inputTokenObj = {
    name: tvlCallResp[10][0],
    symbol: tvlCallResp[11][0],
    decimals: tvlCallResp[12][0],
    address: poolToken,
    allowence: '0',
    balance: '0',
    isSingleSided: false
  }
  let token0Obj = {
    name: tvlCallResp[1][0],
    symbol: tvlCallResp[2][0],
    decimals: tvlCallResp[3][0],
    address: lpTokensResp[0][0],
    allowence: '0',
    balance: '0',
    isSingleSided: false
  }
  let token1Obj = {
    name: tvlCallResp[4][0],
    symbol: tvlCallResp[5][0],
    decimals: tvlCallResp[6][0],
    address: lpTokensResp[1][0],
    allowence: '0',
    balance: '0',
    isSingleSided: false
  }
  let farm = {
    pid: pid,
    lpAddress: poolToken,
    name: poolInfo.pool.name,
    accRewardPerShare: poolInfo.pool.accRewardPerShare.toString(),
    lastRewardTime: poolInfo.pool.lastRewardTime.toString(),
    tvl: new BigNumber(tvlCallResp[0].toString()).dividedBy(10 ** 18).toString(),
    poolToken: poolToken,
    apr,
    rewardRate: rewardRate.toString(),
    lpPrice,
    inputTokens: [inputTokenObj, token0Obj, token1Obj],
    rewardToken: rewardTokenDetails,
    stakedAmount: '0',
    rewardsAmount: '0',
    userPositions: [],
    assets: {
      token0: token0Obj,
      token1: token1Obj
    }
  }
  if (
    farm.assets.token0.symbol.toLowerCase() === 'usdc' ||
    farm.assets.token1.symbol.toLowerCase() === 'usdc' ||
    farm.assets.token0.symbol.toLowerCase() === 'usdt' ||
    farm.assets.token1.symbol.toLowerCase() === 'usdt' ||
    farm.assets.token0.symbol.toLowerCase() === 'dai' ||
    farm.assets.token1.symbol.toLowerCase() === 'dai'
  ) {
    let reserveUSDC = '0'
    let reserveOtherToken = '0'
    if (farm.assets.token1.symbol.toLowerCase() === 'usdc') {
      reserveUSDC = new BigNumber(lpTokensResp[2][1].toString()).dividedBy(10 ** farm.assets.token1.decimals).toString()
      reserveOtherToken = new BigNumber(lpTokensResp[2][0].toString())
        .dividedBy(10 ** farm.assets.token0.decimals)
        .toString()
    } else {
      reserveOtherToken = new BigNumber(lpTokensResp[2][1].toString())
        .dividedBy(10 ** farm.assets.token1.decimals)
        .toString()
      reserveUSDC = new BigNumber(lpTokensResp[2][0].toString()).dividedBy(10 ** farm.assets.token0.decimals).toString()
    }
    const priceOfOtherToken = new BigNumber(reserveUSDC).dividedBy(reserveOtherToken).toString()
    // const tvlUsdc = new BigNumber(2).multipliedBy(reserveUSDC).toString()
    lpPrice = new BigNumber(2)
      .multipliedBy(reserveUSDC)
      .dividedBy(new BigNumber(lpTokensResp[3][0].toString()).dividedBy(10 ** 18))
      .toString()
    const rewardsPerYearUsd = rewardsPerYear.multipliedBy(priceOfOtherToken)
    apr = new BigNumber(rewardsPerYearUsd)
      .dividedBy(new BigNumber(lpPrice).multipliedBy(farm.tvl))
      .multipliedBy(100)
      .toString()
  }
  if (account) {
    let peningRewards = new BigNumber(0)
    let staked = new BigNumber(0)
    const erc20calls = [
      {
        address: poolToken,
        name: 'balanceOf',
        params: [account]
      },
      {
        address: poolToken,
        name: 'allowance',
        params: [account, RELIQUERY_ADDRESS]
      },
      {
        address: token0Obj.address,
        name: 'balanceOf',
        params: [account]
      },
      {
        address: token0Obj.address,
        name: 'allowance',
        params: [account, SINGLE_SIDED_LIQUIDITY]
      },
      {
        address: token1Obj.address,
        name: 'balanceOf',
        params: [account]
      },
      {
        address: token1Obj.address,
        name: 'allowance',
        params: [account, SINGLE_SIDED_LIQUIDITY]
      }
    ]
    const resp = await multicall(ERC20_ABI, erc20calls, multicallContract)
    inputTokenObj = {
      ...inputTokenObj,
      allowence: new BigNumber(resp[1].toString()).dividedBy(10 ** parseFloat(inputTokenObj.decimals)).toString(),
      balance: new BigNumber(resp[0].toString()).dividedBy(10 ** parseFloat(inputTokenObj.decimals)).toString()
    }
    token0Obj = {
      ...token0Obj,
      allowence: new BigNumber(resp[3].toString()).dividedBy(10 ** parseFloat(token0Obj.decimals)).toString(),
      balance: new BigNumber(resp[2].toString()).dividedBy(10 ** parseFloat(token0Obj.decimals)).toString()
    }
    token1Obj = {
      ...token1Obj,
      allowence: new BigNumber(resp[5].toString()).dividedBy(10 ** parseFloat(token1Obj.decimals)).toString(),
      balance: new BigNumber(resp[4].toString()).dividedBy(10 ** parseFloat(token1Obj.decimals)).toString()
    }
    const relicUserCalls = [
      {
        address: RELIQUERY_ADDRESS,
        name: 'pendingRewardsOfOwner',
        params: [account]
      },
      {
        address: RELIQUERY_ADDRESS,
        name: 'relicPositionsOfOwner',
        params: [account]
      }
    ]
    const relicUseresp = await multicall(reliqueryAbi, relicUserCalls, multicallContract)
    const checkApprovedCalls = relicUseresp[1].relicIds.map((relic: string) => ({
      address: RELIQUERY_ADDRESS,
      name: 'isApprovedOrOwner',
      params: [SINGLE_SIDED_LIQUIDITY, relic.toString()]
    }))
    const approvalCheck = await multicall(reliqueryAbi, checkApprovedCalls, multicallContract)
    relicUseresp[0].pendingRewards.forEach((reward: { pendingReward: { toString: () => BigNumber.Value } }) => {
      peningRewards = peningRewards.plus(reward.pendingReward.toString())
    })
    relicUseresp[1].positionInfos.forEach(
      (
        position: {
          amount: BigNumber.Value
          entry: { toString: () => any }
          rewardCredit: { toString: () => any }
          rewardDebt: { toString: () => any }
          level: { toString: () => any }
        },
        index: string | number
      ) => {
        staked = staked.plus(position.amount.toString())
        userPositions.push({
          relicId: relicUseresp[1].relicIds[index].toString(),
          amount: new BigNumber(position.amount.toString()).dividedBy(10 ** 18).toString(),
          duration: position.entry.toString(),
          rewardCredit: position.rewardCredit.toString(),
          rewardDebt: position.rewardDebt.toString(),
          level: position.level.toString(),
          isApproved: approvalCheck[index][0],
          pendingReward: new BigNumber(relicUseresp[0].pendingRewards[index].pendingReward.toString())
            .dividedBy(10 ** 18)
            .toString()
        })
      }
    )
    farm.stakedAmount = staked.dividedBy(10 ** 18).toString()
    farm.rewardsAmount = peningRewards.dividedBy(10 ** 18).toString()
  }
  farm = {
    ...farm,
    apr,
    lpPrice: lpPrice.toString(),
    inputTokens: [inputTokenObj, token0Obj, token1Obj],
    rewardToken: rewardTokenDetails,
    assets: {
      token0: token0Obj,
      token1: token1Obj
    },
    // @ts-ignore
    userPositions: userPositions ? userPositions : [],
    tvl: new BigNumber(farm.tvl).multipliedBy(lpPrice).toString()
  }
  return farm
}

export const updateFarmFromPid = () => {
  // const dispatch = useDispatch<AppDispatch>()
}

export const useSetFarms = () => {
  const dispatch = useDispatch<AppDispatch>()
  const reliqueryContract = useReliqueryContract()
  const { account } = useActiveWeb3React()
  const multicallContract = useMulticallContract()
  const owanalbleCurveContract = useOwanalbleCurveContract()
  // dispatch(setFarms({ farms: farms }))
  useEffect(() => {
    const getFarms = async () => {
      const farms: FarmsInfo[] = []
      if (reliqueryContract && owanalbleCurveContract) {
        const poolLength = await reliqueryContract.poolLength()
        const rewardToken = await reliqueryContract.rewardToken()
        const rewardRate = await owanalbleCurveContract.getRate('0')

        for (let index = 0; index < parseFloat(poolLength.toString()); index++) {
          const farm = await getFarmFromPid(
            index.toString(),
            rewardToken.toString(),
            rewardRate.toString(),
            multicallContract,
            account
          )
          farms.push(farm)
        }
      }
      dispatch(setFarms({ farms: farms }))
      console.log('farms', farms)
    }
    getFarms()
    // return farms
  }, [account, dispatch, multicallContract, owanalbleCurveContract, reliqueryContract])
}
export const useGetFarmFromPid = (pid: string) => {
  const farms = useSelector<AppState, AppState['farms']['farms']>(state => state.farms.farms)
  const farm = farms.find(farm => farm.pid.toString() === pid.toString())
  return farm
}
